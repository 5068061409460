export default defineNuxtPlugin(() => {
  if ('at' in Array.prototype) return

  Array.prototype.at = function (index: number) {
    index = Math.trunc(index) || 0
    if (index < 0) index += this.length
    if (index < 0 || index >= this.length) return undefined
    return this[index]
  }
})
