import payload_plugin_qLmFnukI99 from "/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ghbUAjaD3n from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import nuxt_plugin_6wEQMY3tee from "/app/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_tbFNToZNim from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import components_client_ZB1W0EeH0E from "/app/node_modules/nuxt-swiper/dist/runtime/plugins/components.client.js";
import plugin_eTVJQYlCmx from "/app/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import a_fetch_headers_lUNYvxtiHy from "/app/plugins/a-fetch-headers.ts";
import apollo_1ILFkzpYSr from "/app/plugins/apollo.ts";
import digitsonly_client_JlKrdXvnR4 from "/app/plugins/digitsonly.client.ts";
import eventBus_P6CbRuQa3W from "/app/plugins/eventBus.ts";
import getCallCenterStatus_client_yiL8VSBFbO from "/app/plugins/getCallCenterStatus.client.ts";
import getCity_client_zTdapkWBdj from "/app/plugins/getCity.client.ts";
import polyfills_client_xo6BY28vLt from "/app/plugins/polyfills.client.ts";
import resizeWindow_client_iSizQNLmqD from "/app/plugins/resizeWindow.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import setCookie_client_TL03THu3iK from "/app/plugins/setCookie.client.ts";
import thumbor_UpGnkcp3IL from "/app/plugins/thumbor.ts";
import userInfo_client_6ZKR40J43U from "/app/plugins/userInfo.client.ts";
import utm_client_Rt9eeqWLJa from "/app/plugins/utm.client.ts";
import vuetify_7h9QAQEssH from "/app/plugins/vuetify.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_ghbUAjaD3n,
  nuxt_plugin_6wEQMY3tee,
  plugin_tbFNToZNim,
  components_client_ZB1W0EeH0E,
  plugin_eTVJQYlCmx,
  a_fetch_headers_lUNYvxtiHy,
  apollo_1ILFkzpYSr,
  digitsonly_client_JlKrdXvnR4,
  eventBus_P6CbRuQa3W,
  getCallCenterStatus_client_yiL8VSBFbO,
  getCity_client_zTdapkWBdj,
  polyfills_client_xo6BY28vLt,
  resizeWindow_client_iSizQNLmqD,
  sentry_client_shVUlIjFLk,
  setCookie_client_TL03THu3iK,
  thumbor_UpGnkcp3IL,
  userInfo_client_6ZKR40J43U,
  utm_client_Rt9eeqWLJa,
  vuetify_7h9QAQEssH
]