// import parsePhoneNumber from 'libphonenumber-js/mobile'

// export const validPhone = v => !!parsePhoneNumber(String(v))?.isValid()
export const validPhone = (v: unknown): boolean => {
  if (typeof v !== 'string') return false

  const digits = v.replace(/\D/g, '')
  const phoneRegex = /^9\d{9}$/

  return phoneRegex.test(digits)
}

export const validDate = (v) => {
  const dobRegex = /^(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|1[0-2])\.(19|20)\d{2}$/

  if (!dobRegex.test(v)) return false

  const parts = v.split('.')
  const day = parseInt(parts[0], 10)
  const month = parseInt(parts[1], 10)
  const year = parseInt(parts[2], 10)

  const currentDate = new Date()
  const inputDate = new Date(year, month - 1, day)

  if (inputDate > currentDate) return false

  return true
}
