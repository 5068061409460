import { useMainStore } from '~/store/main/main.store'
import { useCities } from '~/store/cites/cities.store'

export default async function getPageText(input?) {
  const cityStore = useCities()
  const mainStore = useMainStore()

  const currentCity = computed(() => cityStore.getCity)
  const provider = mainStore.getDomainConfig
  try {
    const res = await $fetch(`${mainStore.apiDomain}/providers/${provider?.provider?.id || 0}/pages/${input?.url}`, {
      method: 'GET',
      params: {
        domain: provider?.domain || 'dominternet.ru',
        fias_id: currentCity.value?.fias_id,
        ...input,
        url: undefined,
      },
    })

    return res ?? null
  }
  catch (error) {
    console.error('Ошибка загрузки страницы:', error)
    return null
  }
}
