import { useCities } from '~/store/cites/cities.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { useMainStore } from '~/store/main/main.store'
import { useCompare } from '~/composible/setFavourites'
import { getCookieId } from '~/helpers'

export function createInteraction() {
  const $route = useRoute()
  const cityStore = useCities()
  const tariffsStore = useTariffs()
  const mainStore = useMainStore()
  const { compareListTariffs } = useCompare()

  const tariffsViewed = useCookie<string[]>('tariffsViewed')
  const abTestCookie = useCookie<string>('ab_test')
  const partnerId = useCookie<string>('partner_id')

  const ctx = useNuxtApp()

  const getAddressData = (address = {}) => ({
    auto_url: cityStore.getHouseUrl,
    full_address: cityStore.getCertainty === 'FALLBACK' ? undefined : cityStore.getAddressText?.fullAddress + (address.flat ? ', кв. ' + address.flat : ''),
    city_fias_id: cityStore.getCertainty === 'FALLBACK' ? undefined : cityStore.getCity?.fias_id,
    street_fias_id: cityStore.getDataStreet?.fias_id,
    house_id: cityStore.getHouseId || undefined,
    house_dadata: cityStore.getHouse?.dadata || {},
    ...address,
  })

  const getMetaData = (meta = {}) => ({
    utm: mainStore.getUtmArr?.length ? Object.fromEntries(mainStore.getUtmArr) : undefined,
    request_type: 'CALL',
    at_url: `https://${mainStore.getDomainConfig?.domain || 'dominternet.ru'}${$route.fullPath}`,
    yandex_id: getCookieId('_ym_uid', /^(\d+)$/),
    google_id: getCookieId('_ga', /(\d+\.\d+)$/),
    site_type: mainStore.getDomainConfig
      ? undefined
      : mainStore.getDiProvider
        ? 'provider'
        : 'aggregator',
    site_city_fias_id: cityStore.getCity?.fias_id,
    site_street_fias_id: cityStore.getDataStreet?.fias_id,
    site_house_id: cityStore.getHouseId || 0,
    site_house_dadata: cityStore.getHouse?.dadata || {},
    site_version: abTestCookie.value || undefined,
    ...meta,
  })

  const sendInteractionRequest = async (
    method: 'POST' | 'PATCH',
    id?: string,
    complete?: boolean,
    lead?: any,
    tariff?: any,
    meta?: any,
    extra?: any,
    client?: any,
    address?: any,
  ) => {
    try {
      return await $fetch(`${mainStore.apiDomain}/interactions/${id ? `${id}/` : ''}`, {
        method,
        body: {
          lead,
          complete,
          address: getAddressData(address),
          client,
          tariff: {
            comparison_tariff_ids: compareListTariffs.value?.map(el => el.replace(/\D/g, '')),
            opened_tariff_ids: tariffsViewed.value?.map(el => el.replace(/\D/g, '')),
            ...tariff,
            provider_id:
              tariff?.provider_id || (!mainStore.getDomainConfig ? tariffsStore.getPassThroughProviderId : null) || undefined,
          },
          meta: getMetaData(meta),
          extra,
          partner_id: partnerId.value ? +partnerId.value : undefined,
        },
      })
    }
    catch (e) {
      console.error(e)
      ctx.$sentryCaptureMessage(JSON.stringify({
        lead,
        complete,
        address: getAddressData(address),
        client,
        tariff: {
          comparison_tariff_ids: compareListTariffs.value?.map(el => el.replace(/\D/g, '')),
          opened_tariff_ids: tariffsViewed.value?.map(el => el.replace(/\D/g, '')),
          ...tariff,
          provider_id:
            tariff?.provider_id || (!mainStore.getDomainConfig ? tariffsStore.getPassThroughProviderId : null) || undefined,
        },
        meta: getMetaData(meta),
        extra,
        partner_id: partnerId.value ? +partnerId.value : undefined,
      }))
      ctx.$sentryCaptureMessage(e)
    }
  }
  const cI = (complete: boolean, lead?: any, tariff?: any, meta?: any, extra?: any, client?: any, address?: any) => {
    return sendInteractionRequest('POST', undefined, complete, lead, tariff, meta, extra, client, address)
  }

  const uI = (id: string, complete: boolean, lead?: any, tariff?: any, meta?: any, extra?: any, client?: any, address?: any) => {
    return sendInteractionRequest('PATCH', id, complete, lead, tariff, meta, extra, client, address)
  }

  return { cI, uI }
}
