import { useMainStore } from '~/store/main/main.store'

export function callTimeComposible() {
  const mainStore = useMainStore()

  const timeToCall = ref<string>('')
  const timeToUpdate = ref<boolean>(true)

  const formatTime = (dateString: string): string => {
    return new Date(dateString)
      .toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })
  }

  const timeList = computed(() => {
    return mainStore.getCallSlots?.slots.flatMap(el =>
      el.slots.map(e => ({
        value: { day: el.day, slot: e },
        text: `${el.human_day.toLowerCase()} с ${formatTime(e.start)} до ${formatTime(e.end)}`,
      })),
    ) || []
  })
  const timeNow = computed(() => {
    return {
      value: {},
      text: mainStore.getCallSlots?.display_right_away
        ? 'в течение 15 минут'
        : 'в рабочее время',
    }
  })
  const callTime = computed(() => {
    const selected = timeList.value.find(el => el.text === timeToCall.value)
    return selected?.value.slot.start
  })

  const setTimeToCall = (time: { text: string }) => {
    timeToCall.value = time.text
  }

  const showActiveSortList = (e: Event) => {
    if (e && timeToUpdate.value) {
      mainStore.fetchCallSlots()
      timeToUpdate.value = false
      setTimeout(() => (timeToUpdate.value = true), 60000)
    }
  }

  return {
    timeList,
    timeNow,
    timeToCall,
    callTime,
    setTimeToCall,
    showActiveSortList,
  }
}
