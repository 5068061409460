import { useMainStore } from '~/store/main/main.store'

export function getOrderUrl() {
  const router = useRouter()
  const mainStore = useMainStore()
  const partner = toRef(mainStore, 'getDomainConfig')

  if (partner.value) {
    return router.push('/complete/')
  }

  async function getUrl() {
    try {
      if (!mainStore.apiDomain) {
        return router.push('/personal/complete/')
      }
      const res = await $fetch(`${mainStore.apiDomain}/graphql/`, {
        method: 'POST',
        body: {
          operationName: 'getShortUid',
          variables: { byShortUid: null },
          query: `
            query getShortUid($byShortUid: String) {
              personal {
                getInteractionOrders(byShortUid: $byShortUid) {
                  shortUid
                }
              }
            }
          `,
        },
      })

      const shortUid = res?.data?.personal?.getInteractionOrders?.[0]?.shortUid

      nextTick(() => {
        router.push(shortUid ? `/personal/order/${shortUid}/` : `/personal/complete/`)
      })
    }
    catch (error) {
      console.error('Ошибка при получении shortUid:', error)
      router.push('/personal/complete/')
    }
  }

  return getUrl()
}
