import type { VNode, VNodeDirective } from 'vue'
import { getNumCharacters, queryInputElementInside } from '~/helpers'

export default defineNuxtPlugin({
  name: 'digitsOnly',
  parallel: true,
  setup(nuxtApp) {
    nuxtApp.vueApp.directive('digitsonly', {
      beforeMount(el, binding, vnode) {
        const input = queryInputElementInside(el)
        const handlerFn = (e) => {
          if (e && e.isTrusted) handler(e, vnode, input, binding)
        }

        input._digitsHandler = handlerFn
        input.addEventListener('input', handlerFn)
      },
      unmounted(el, binding, vnode) {
        const input = queryInputElementInside(el)

        if (input._digitsHandler) {
          input.removeEventListener('input', input._digitsHandler)
          delete input._digitsHandler
        }
      },
    })

    function handler(
      e: Event,
      vnode: VNode,
      input: HTMLInputElement,
      binding?: VNodeDirective,
    ) {
      if (input.selectionStart && input.selectionStart < 3) {
        e.preventDefault()
      }

      input.value = getNumCharacters(
        (e.target as HTMLInputElement).value,
        binding,
      )
      queryInputElementInside(vnode.el)?.dispatchEvent(new CustomEvent('input'))
    }
  },
})
