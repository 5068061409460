<script setup lang="ts">
const swiperContainer = ref()
onMounted(() => {
  nextTick(() => {
    const style = document.createElement('style')
    style.innerHTML = '.swiper-button-disabled {  display: none !important; }'
    swiperContainer.value.shadowRoot.appendChild(style)
  })
})
</script>

<template>
  <div class="swiper-wrap relative">
    <swiper-container
      ref="swiperContainer"
      slides-per-view="auto"
      :space-between="20"
      :threshold="3"
      :navigation="true"
      :pagination="{ clickable: true }"
      itemscope
      itemtype="http://schema.org/ItemList"
      v-bind="$attrs"
    >
      <slot />
    </swiper-container>
    <div class="swiper-btn-wrap swiper-btn-wrap--prev" />
    <div class="swiper-btn-wrap" />
  </div>
</template>
