import { useMainStore } from '~/store/main/main.store'

const AllPossibleKeys = [
  'providers',
  'tech',
  'houseType',
  'providerSlugs',
  'service',
  'serviceSets',
  'serviceSetsContains',
  'additionalServices',
  'serviceContainsFallback',
  'enableSubscriptions',
  'subscriptionAny',
  'freeConnection',
  'gaming',
  'promo',
  'recommend',
  'fastConnection',
  'hasWifi',
  'routerIncluded',
  'enableDevices',
  'smartFilter',
  'smartFilterFallback',
  'forPlace',
  'tags',
  'sort',
  'category',
  'autoSelection',
  'subscriptionIds',
  'subscriptionService',
  'subscriptionServiceIds',
  'speedMin',
  'speedMax',
  'priceMin',
  'priceMax',
  'priceRange',
  'speedRange',
  'limit',
  'offset',
]

export const isEmpty = (target): boolean => {
  return (
    target !== null
    && typeof target === 'object'
    && !Array.isArray(target)
    && Object.keys(target).length === 0
  )
}

export function textReplace(text, obj) {
  if (!text) return ''
  return text.replace(/%(\S*?)%/gu, function (m) {
    return obj[m] || obj[m] === 0 ? obj[m] : m
  })
}
export function imgUrl(path: string, w?: number, h?: number) {
  const { $thumborURL } = useNuxtApp()
  const domain = useMainStore().getDomain

  const fullPath = /^https?:\/\//.test(path) ? path : domain + path
  const url = $thumborURL.setImagePath(fullPath).resize(w, h).smartCrop(true).buildUrl()
  return url
}

export function textAggReplace(
  text: string,
  getAddressText,
  textAgg?,
  provider?,
) {
  if (!text) return ''
  return textReplace(text, {
    '%TODAY%': new Date().toLocaleString('ru-RU', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }),
    '%YEAR%': new Date().getFullYear(),
    '%PROVIDER%': provider,
    '%FULL_ADDRESS%': getAddressText.fullAddress,
    '%CITY%': getAddressText.cityOnly,
    '%CITY_LOC%': getAddressText.cityOnlyLoc,
    '%CITY_LOC_START%': getAddressText.cityOnlyLoc?.replace('в', 'В'),
    '%CITY_POS%': getAddressText.cityOnlyPos,
    '%AREA%': getAddressText.areaOnly,
    '%AREA_LOC%': getAddressText.areaOnlyLoc,
    '%AREA_POS%': getAddressText.areaOnlyPos,
    '%STREET%': getAddressText.streetOnly,
    '%HOUSE%': getAddressText.houseOnly,
    '%AREA_LOC_START%': getAddressText.areaOnlyLoc?.replace('в', 'В'),
    '%CITY_URL%': getAddressText.cityUrl,
    '%HOUSES_COUNT%': textAgg?.location?.linkHousesCount,
    '%PRICE_MIN%': textAgg?.tariffsAgg?.priceRange?.min,
    '%PRICE_MAX%': textAgg?.tariffsAgg?.priceRange?.max,
    '%SPEED_MIN%': textAgg?.tariffsAgg?.speedRange?.min,
    '%SPEED_MAX%': textAgg?.tariffsAgg?.speedRange?.max,
    '%PROVIDERS_COUNT%': textAgg?.tariffsAgg?.providersCount,
    '%TARIFFS_COUNT%': textAgg?.tariffsAgg?.tariffsCount,
    '%PROVIDERS_COUNT_WW%':
      textAgg?.tariffsAgg?.providersCount
      || textAgg?.tariffsAgg?.providersCount === 0
        ? textAgg?.tariffsAgg?.providersCount
        + ' провайдер'
        + counter(textAgg?.tariffsAgg?.providersCount)
        : '',
    '%TARIFFS_COUNT_WW%':
      textAgg?.tariffsAgg?.tariffsCount
      || textAgg?.tariffsAgg?.tariffsCount === 0
        ? textAgg?.tariffsAgg?.tariffsCount
        + ' тариф'
        + counter(textAgg?.tariffsAgg?.tariffsCount)
        : '',
    '%CHANNELS_MAX_WW%':
      textAgg?.tariffsAgg?.tvChannelsRange?.max
      || textAgg?.tariffsAgg?.tvChannelsRange?.max === 0
        ? textAgg?.tariffsAgg?.tvChannelsRange?.max
        + ' канал'
        + counter(textAgg?.tariffsAgg?.tvChannelsRange?.max)
        : '',
    '%DISCOUNT_MAX%': textAgg?.tariffsAgg?.maxDiscountPercent,
    '%CHANNELS_MAX%': textAgg?.tariffsAgg?.tvChannelsRange?.max,
    '%MINUTES_MAX%': textAgg?.tariffsAgg?.mobileMinutesRange?.max,
    '%GB_MAX%': textAgg?.tariffsAgg?.mobileGigabytesRange?.max,
    '%SUBSCRIPTIONS%': textAgg?.tariffsAgg?.availableSubscriptionServices
      ?.map(el => el.name)
      .join(', '),
    '%PROVIDERS_FROM_WW%': textAgg?.tariffsAgg?.availableProviders?.length
      ? 'провайдер'
      + counterFrom(textAgg?.tariffsAgg?.availableProviders?.length)
      + ' '
      + textAgg?.tariffsAgg?.availableProviders
        ?.map(el => el.name)
        .join(', ')
      : '',
    '%PROVIDERS%': textAgg?.tariffsAgg?.availableProviders
      ?.map(el => el.name)
      .join(', '),
    '%PROVIDERS_FAST%': textAgg?.groupedTariffs?.fastestProviders?.data
      ?.map(el => el.provider.name)
      .join(', '),
    '%PROVIDERS_CHEAP%': textAgg?.groupedTariffs?.cheapestProviders?.data
      ?.map(el => el.provider.name)
      .join(', '),
    '%PROVIDERS_POPULAR%': textAgg?.groupedTariffs?.popularProviders?.data
      ?.map(el => el.provider.name)
      .join(', '),
    '%TARIFF_FAST%': textAgg?.groupedTariffs?.fastestTariff?.data?.[0]
      ? textAgg.groupedTariffs.fastestTariff.data[0].name
      + ' от '
      + textAgg.groupedTariffs.fastestTariff.data[0].provider.name
      : '',
    '%TARIFF_CHEAP%': textAgg?.groupedTariffs?.cheapestTariff?.data?.[0]
      ? textAgg.groupedTariffs.cheapestTariff.data[0].name
      + ' от '
      + textAgg.groupedTariffs.cheapestTariff.data[0].provider.name
      : '',
    '%TARIFF_CHANNELS_MAX%': textAgg?.groupedTariffs?.mostTvTariff?.data?.[0]
      ? textAgg.groupedTariffs.mostTvTariff.data[0].name
      + ' от '
      + textAgg.groupedTariffs.mostTvTariff.data[0].provider.name
      : '',
  })
}

export function goAway(link: string) {
  window.open(link, '_blank', 'noopener,noreferrer')
}

export function omit(obj: object, keys: string[]) {
  const keysToRemove = new Set(keys.flat()) // flatten the props, and convert to a Set

  return Object.fromEntries(
    // convert the entries back to object
    Object.entries(obj) // convert the object to entries
      .filter(([k]) => !keysToRemove.has(k)), // remove entries with keys that exist in the Set
  )
}

export function hexToRgb(hex) {
  if (hex) {
    hex = hex.replace(/^#/, '')

    const r = Number.parseInt(hex.substring(0, 2), 16)
    const g = Number.parseInt(hex.substring(2, 4), 16)
    const b = Number.parseInt(hex.substring(4, 6), 16)

    return `${r}, ${g}, ${b}`
  }
}

export function getNumCharacters(v, binding?) {
  if (binding?.arg === 'date') {
    return String(v)
      .replace(/[^\d.]/g, '')
      .replace(/(\S{10}).*/g, '$1')
      .replace(
        /^(0?[1-9]|[12]\d|3[01])(0?[1-9]|1[0-2])(19|20)\d{2}/,
        '$1.$2.$3',
      )
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2}\.\d{2})(\d)/, '$1.$2')
  }
}

export function queryInputElementInside(el): HTMLInputElement {
  return el instanceof HTMLInputElement ? el : el.querySelector('input') || el
}

export const counter = (val, s = '', d = 'а', m = 'ов') => {
  return val % 10 > 4 || val % 10 === 0 || (val % 100 > 4 && val % 100 < 20)
    ? m
    : val % 10 === 1
      ? s
      : d
}

export const numberFormat = (value: number) => {
  return typeof value === 'number'
    ? new Intl.NumberFormat().format(value)
    : value
}

export const counterFrom = (val, s = 'а', m = 'ов') => {
  return val % 10 === 1 && val !== 11 ? s : m
}

export function debounce<T extends (...args: any[]) => void>(func: T, timeout = 400): T {
  let timer: ReturnType<typeof setTimeout>
  return ((...args: Parameters<T>) => {
    clearTimeout(timer)
    timer = setTimeout(() => func(...args), timeout)
  }) as T
}

export function throttle(callee, timeout) {
  let timer = null

  return function perform(...args) {
    if (timer) return

    timer = setTimeout(() => {
      callee(...args)

      clearTimeout(timer)
      timer = null
    }, timeout)
  }
}
export function getCookieId(name: string, regex: RegExp): string {
  const match = document.cookie.match(`(?:^|;)\\s*${name}=([^;]*)`)
  const raw = match ? decodeURIComponent(match[1]) : ''
  return regex.test(raw) ? raw.match(regex)?.[1] || '' : ''
}

export function getFiltersFromUrl(url) {
  const $route = useRoute()
  const params = new URLSearchParams(url.replace(/.*\?([^#]*)/, '$1'))

  const filters: Record<string, any> = {}

  params.forEach((value, key) => {
    if (AllPossibleKeys.includes(key)) {
      filters[key.replace('_m', 'M').replace('subscriptionService', 'subscriptionServiceIds')] = ['tags', 'providers', 'tech', 'service', 'serviceSets', 'serviceSetsContains', 'subscriptionService', 'subscription'].includes(key)
        ? value.split(',')
        : value === 'true'
          ? true
          : value
    }
  })

  if ($route.query.sorting) filters.sort = $route.query.sorting
  if ($route.query.smart_filter) filters.smartFilter = $route.query.smart_filter
  if ($route.query['auto-selection']) filters.autoSelection = +$route.query['auto-selection']

  return filters
}
