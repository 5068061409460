<script setup lang="ts">
import { useCities } from '~/store/cites/cities.store'

const $route = useRoute()
const $router = useRouter()
const cityStore = useCities()
const getAddressText = computed(() => cityStore.getAddressText)
const houseUrl = computed(() => cityStore.getHouseUrl)
const needGetCity = computed(() => cityStore.getNeedGetCity)
const headerLight = computed(() => {
  return (
    $route.name !== 'city-url'
    && $route.name !== 'city-for-house'
    && $route.name !== 'city-streets-street'
    && $route.name !== 'city-providers-to-address'
    && $route.name !== 'providers-to-address'
    && $route.name !== 'index'
    && $route.name !== 'speedtest'
    && $route.name !== 'speedtest-rt-test'
  )
})
</script>

<template>
  <component
    :is="$route.name === 'index' ? 'div' : 'a'"
    :href="$route.name === 'index' ? undefined : '/'"
    class="logo"
    @click.prevent="$router.push('/')"
  >
    <img
      v-if="headerLight"
      loading="lazy"
      src="~/assets/images/logo.svg"
      alt="logo"
    >
    <img
      v-else
      loading="lazy"
      src="~/assets/images/logo-white.svg"
      alt="logo"
    >
    {{
      houseUrl || needGetCity
        ? "ДомИнтернет"
        : `Домашний интернет ${getAddressText.cityOnlyLoc}`
    }}
  </component>
</template>

<style scoped lang="scss">

</style>
